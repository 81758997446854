/*
 * @Author: your name
 * @Date: 2021-11-08 11:07:23
 * @LastEditTime: 2021-12-23 11:26:04
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \交建\traffic-construction-web\src\api\general-user\org-management.js
 */
import request from '@/utils/request.js';
/**
 * 获取机构性质列表
 * @returns {Promise}
 */
export function getNatureList() {
  return request({
    url: '/traffic-construction/getNatureList',
    method: 'get'
  });
}
/**
 * 获取机构列表
 * @returns {Promise}
 */
export function getOrgList(params) {
  return request({
    url: '/traffic-construction/getPage1',
    method: 'get',
    params
  });
}
/**
 * 新增/编辑机构
 * @returns {Promise}
 */
export function postOrgInfo(data) {
  return request({
    url: '/traffic-construction/addOrEditOrg1',
    method: 'post',
    data
  });
}
/**
 * 删除机构
 * @returns {Promise}
 */
export function deleteOrg(data) {
  return request({
    url: '/traffic-construction/deleteOrg',
    method: 'post',
    data
  });
}

/**
 * 根据上级机构获取 机构性质下拉列表
 * @returns {Promise}
 */
export function getListByOrg(params) {
  return request({
    url: '/traffic-construction/getListByOrg1',
    method: 'get',
    params
  });
}

/**
 * 获取机构下拉列表(排除最后一级机构‘标段’)
 * @returns {Promise}
 */
export function getOrgDownList(params) {
  return request({
    url: '/traffic-construction/getOrgDownList1',
    method: 'get',
    params
  });
}
/**
 * 获取管理项目部
 * @returns {Promise}
 */
export function getAllProjectDepartment(params) {
  return request({
    url: '/traffic-construction/getUnmanagedProject',
    method: 'get',
    params
  });
}
