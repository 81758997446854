<template>
  <div class="wrapper">
    <div>
      <org-tree ref="treeRef" @node-click="handleNodeClick"></org-tree>
    </div>
    <div class="table">
      <!-- query nav -->
      <el-form ref="form" :inline="true" :model="queryForm" label-width="80px">
        <el-form-item label="机构名称" prop="name">
          <el-input v-model="queryForm.name" placeholder="请输入" style="width:210px"></el-input>
        </el-form-item>
        <el-form-item label="机构性质" prop="nature">
          <el-select v-model="queryForm.nature" placeholder="请选择" style="width:210px">
            <el-option
              v-for="item in natureList"
              :key="item.nature"
              :label="item.name"
              :value="item.nature"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleSearch">查询</el-button>
          <el-button @click="handleReset">重置</el-button>
        </el-form-item>
      </el-form>

      <div class="action-bar">
        <el-button v-if="$check('env-organizational-manage_add')" type="primary" icon="el-icon-plus" @click="handleAdd"
          >新增</el-button
        >
        <el-button @click="toImage">导出组织架构图</el-button>
      </div>
      <!-- table -->
      <el-table v-loading="tableLoading" :data="orgList">
        <el-table-column align="center" width="80" type="index" label="序号"></el-table-column>
        <el-table-column align="center" prop="orgName" label="机构名称" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" width="300" prop="nature" label="机构性质"></el-table-column>
        <el-table-column align="center" width="200" prop="createTime" label="操作">
          <template slot-scope="{ row }">
            <div>
              <span v-if="$check('env-organizational-manage_edit')" class="action-btn mr-24" @click="handleEdit(row)"
                >修改</span
              >
              <span
                v-if="$check('env-organizational-manage_delete')"
                class="action-btn danger-btn"
                @click="handleDelete(row)"
                >删除</span
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :total="total"
        :current-page="queryForm.pageNo"
        :page-size="queryForm.pageSize"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="[10, 20, 30, 50]"
        layout="total, sizes, prev, pager, next"
      ></el-pagination>
    </div>
    <!-- 新增/编辑弹窗 -->
    <org-dialog ref="dialogRef" :nature-list="natureList" @on-success="handleSuccess"></org-dialog>
  </div>
</template>

<script>
import OrgTree from '@/components/OrgTree';
import orgDialog from './orgDialog.vue';
import { getItem } from '@/utils/storage.js';
// api
import { getNatureList, getOrgList, deleteOrg } from '@/api/general-user/org-management.js';
export default {
  components: { OrgTree, orgDialog },
  data() {
    return {
      tableLoading: false,
      natureList: [],
      total: 0,
      queryForm: {
        pageNo: 1,
        pageSize: 10,
        id: '',
        name: '',
        nature: ''
      },
      orgList: []
    };
  },
  created() {
    this.getNatureListData();
    this.getOrgListData();
  },
  methods: {
    getNatureListData() {
      getNatureList().then(res => {
        this.natureList = res.data;
      });
    },
    getOrgListData() {
      this.tableLoading = true;
      getOrgList(this.queryForm)
        .then(res => {
          this.total = res.data.total;
          this.orgList = res.data.records;
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    // 节点被点击时回调
    handleNodeClick(data) {
      this.queryForm.id = data.id;
      this.handleSearch();
    },
    // 分页
    handleSizeChange(size) {
      this.queryForm.pageNo = 1;
      this.queryForm.pageSize = size;
      this.getOrgListData();
    },
    handleCurrentChange(current) {
      this.queryForm.pageNo = current;
      this.getOrgListData();
    },
    // 查询
    handleSearch() {
      this.queryForm.pageNo = 1;
      this.queryForm.pageSize = 10;
      this.getOrgListData();
    },
    // 重置
    handleReset() {
      this.$refs.form.resetFields();
      this.$refs.treeRef.reset();
      this.queryForm.id = '';
      this.getOrgListData();
    },
    // 新增
    handleAdd() {
      this.$refs.dialogRef.open();
    },
    handleSuccess(id) {
      if (id) {
        /* 编辑 */
        this.getOrgListData();
      } else {
        /* 新增 */
        this.handleReset();
      }
      // 刷新机构树
      this.$refs.treeRef.getOrgTreeData();
    },
    // 修改
    handleEdit(row) {
      this.$refs.dialogRef.open(row);
    },
    // 删除
    handleDelete(row) {
      this.$confirm('删除该机构后将会同时删除所有下级机构, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.postDelete(row.id);
      });
    },
    postDelete(id) {
      deleteOrg({ id, appId: getItem('appInfo').appId }).then(res => {
        this.$message.success('删除机构成功！');
        this.handleReset();
        this.$refs.treeRef.getOrgTreeData();
      });
    },
    toImage() {
      this.$refs.treeRef.toImage();
    }
  }
};
</script>

<style lang="less" scoped>
.wrapper {
  height: 100%;
  display: flex;

  .table {
    width: calc(100% - 260px);
  }
}
.action-bar {
  border-top: 1px solid #dcdfe7;
  padding-top: 16px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}
</style>
