<template>
  <el-dialog
    width="530px"
    :title="title"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @close="handleClose"
  >
    <el-form v-loading="loading" ref="form" inline label-width="100px" :model="paramsForm" :rules="rules">
      <el-form-item label="机构名称" prop="name">
        <el-input v-model="paramsForm.name" placeholder="请输入" style="width: 380px"></el-input>
      </el-form-item>
      <el-form-item label="上级机构" prop="pidValue">
        <el-cascader
          v-model="paramsForm.pidValue"
          :options="orgTree"
          :props="defaultProps"
          clearable
          :show-all-levels="false"
          style="width: 380px"
          @change="onChangePid"
        ></el-cascader>
      </el-form-item>
      <el-form-item label="机构性质" prop="nature">
        <el-select
          v-model="paramsForm.nature"
          @change="onChangeOrg"
          placeholder="请先选择上级机构"
          style="width: 380px"
        >
          <el-option v-for="item in natureList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="管理项目部" v-if="isId === 5">
        <el-select
          v-model="paramsForm.projectList"
          @change="onChangePro"
          multiple
          placeholder="请选择管理项目部"
          style="width: 380px"
        >
          <el-option
            v-for="item in ProjectList"
            :key="item.orgId"
            :label="item.orgName"
            :value="item.orgId"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" :loading="confirmLoading" @click="handleConfirm">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { getItem } from '@/utils/storage';
import { getOrgTree } from '@/api/common/index.js';
import {
  postOrgInfo,
  getListByOrg,
  getOrgDownList,
  getAllProjectDepartment
} from '@/api/general-user/org-management.js';

export default {
  data() {
    return {
      tenantId: '',
      loading: false,
      dialogVisible: false,
      title: '新增',
      confirmLoading: false,
      natureList: [],
      ProjectList: [],
      // 没有剔除标段的机构树，数据回显构造父子id对象用
      allOrgTree: [
        {
          name: '顶级',
          id: '',
          children: []
        }
      ],
      // 剔除了标段的机构树，渲染选择用
      orgTree: [
        {
          name: '顶级',
          id: '',
          children: []
        }
      ],
      parentList: {},
      defaultProps: {
        checkStrictly: true,
        value: 'id',
        label: 'name'
      },
      paramsForm: {
        id: '', // 编辑用
        pidValue: [], // 级联选择双向绑定
        pid: '', // 顶级为 0
        name: '',
        nature: '',
        projectList: []
      },
      rules: {
        name: [
          { required: true, message: '请输入机构名称', trigger: 'blur' },
          { max: 30, message: '长度在 30 个字符以内', trigger: 'blur' }
        ],
        nature: [{ required: true, message: '请选择机构性质', trigger: 'change' }],
        pidValue: [{ required: true, message: '请选择上级机构', trigger: 'change' }]
      },
      isId: null,
      isAdd: true,
      proList: [],
      orgId: ''
    };
  },
  created() {
    this.tenantId = getItem('tenantInfo').tenantId;
    this.orgTree[0].id = this.tenantId;
    this.allOrgTree[0].id = this.tenantId;
  },
  methods: {
    async getOrgTreeData(id) {
      const res = await getOrgDownList();
      const allTree = await getOrgTree();
      if (id) {
        this.setDisabled(res.data, id + '');
        this.orgTree[0].children = res.data;
        this.allOrgTree[0].children = allTree.data;
      } else {
        this.orgTree[0].children = res.data;
        this.allOrgTree[0].children = allTree.data;
      }
    },
    async getNatureListByOrg(orgId) {
      const res = await getListByOrg({ orgId });
      this.natureList = res.data;
    },
    open(data = {}) {
      const { id, orgName, natureId, projectList } = data;
      this.dialogVisible = true;
      this.loading = true;
      this.getOrgTreeData(id)
        .then(() => {
          if (id) {
            if (natureId === 5) {
              this.isId = natureId;
            }
            this.getAllProjectDepartment(id);
            this.orgId = id;
            this.isAdd = false;
            if (projectList !== '') {
              this.proList = projectList.split(',');
            }

            this.getParentList(this.allOrgTree);
            return this.getNatureListByOrg(this.parentList[id]);
          } else {
            this.isAdd = true;
            return Promise.reject(new Error('add'));
          }
        })
        .then(
          () => {
            /* 编辑 */
            this.title = '编辑';
            this.$nextTick(() => {
              this.paramsForm.id = id;
              this.paramsForm.name = orgName;
              this.paramsForm.nature = natureId;
              if (projectList !== '') {
                this.paramsForm.projectList = projectList.split(',');
              }
              this.getPidValue(id + '');
            });
          },
          reson => {
            /* 新增 */
            this.title = '新增';
          }
        )
        .finally(() => {
          this.loading = false;
        });
    },
    // 编辑时，禁止选择 节点本身 以及 该节点的子节点
    setDisabled(tree, id) {
      tree.forEach(item => {
        if (item.id === id) {
          item.disabled = true;
          if (item.children?.length) {
            this.setAllDisabled(item.children);
          }
        } else if (item.children?.length) {
          this.setDisabled(item.children, id);
        }
      });
    },
    setAllDisabled(tree) {
      tree.forEach(item => {
        item.disabled = true;
        if (item.children?.length) {
          this.setAllDisabled(item.children);
        }
      });
    },
    // 通过 id 获取 pid 数组
    getPidValue(id) {
      const result = this.findParentList(id);
      result.reverse();
      this.paramsForm.pidValue = result;
    },
    // 构造 父子节点 关系对象
    getParentList(tree) {
      tree.forEach(item => {
        if (item.id !== this.tenantId) {
          const pid = item.pid;
          const oid = item.id;
          this.parentList[oid] = pid;
        }
        if (item.children?.length) {
          this.getParentList(item.children);
        }
      });
    },
    // 通过子节点id, 找出所有上级 id
    findParentList(id, result = []) {
      const pid = this.parentList[id];
      result.push(pid);
      if (pid !== this.tenantId) {
        this.findParentList(pid, result);
      }
      return result;
    },
    onChangePid(idList) {
      this.paramsForm.nature = '';
      this.isId = null;
      this.paramsForm.projectList = [];
      this.getNatureListByOrg(idList[idList.length - 1]);
    },
    handleConfirm() {
      this.$refs.form.validate(valid => {
        if (!valid) return;
        const { pidValue } = this.paramsForm;
        this.paramsForm.pid = pidValue[pidValue.length - 1];
        this.onPostOrgInfo(this.paramsForm);
      });
    },
    onPostOrgInfo(params) {
      this.confirmLoading = true;
      const { id } = params;
      postOrgInfo(params)
        .then(res => {
          this.$message.success(`${id ? '编辑' : '新增'}机构成功！`);
          this.handleClose();
          this.$emit('on-success', id);
        })
        .finally(() => {
          this.confirmLoading = false;
        });
    },
    handleClose() {
      this.$refs.form.resetFields();
      this.paramsForm.id = '';
      this.dialogVisible = false;
      this.natureList = [];
      this.paramsForm.projectList = [];
      this.proList = [];
      this.isId = null;
    },
    onChangeOrg(data) {
      if (this.isAdd) {
        this.paramsForm.projectList = [];
      } else {
        if (data === 5) {
          this.paramsForm.projectList = this.proList;
        } else {
          this.paramsForm.projectList = [];
        }
      }
      this.isId = data;
      if (data === 5) {
        this.getAllProjectDepartment(this.orgId);
      }
    },
    async getAllProjectDepartment(orgId) {
      const res = await getAllProjectDepartment({ orgId });
      this.ProjectList = res.data;
    },
    onChangePro(data) {
      this.proList = data;
    }
  }
};
</script>
